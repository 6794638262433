/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { graphql } from 'gatsby';
import { Page } from '@bodiless/gatsby-theme-bodiless';
import { withSearchResult } from '@bodiless/search';
import Layout from '../../../components/Layout';
import { InlineSearchBox, SimpleSearchResult } from '../../../components/Search';

const SearchLayout = withSearchResult(Layout);

const SearchPage = (props: any) => (
  <Page {...props}>
    <SearchLayout>
      <h1 className="text-3xl font-bold">Search Result</h1>
      <InlineSearchBox />
      <SimpleSearchResult />
    </SearchLayout>
  </Page>
);

export const query = graphql`
  query($slug: String!) {
    ...PageQuery
    ...SiteQuery
  }
`;
export default SearchPage;
